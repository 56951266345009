import { FooterMenuProps } from "../components/Footer/FooterMenu/FooterMenu";

export const footerMenuOptions: FooterMenuProps[] = [
    {
        text: "Games",
        path: "https://www.mobilityware.com/games"
    },
    {
        text: "About Us",
        path: "https://www.mobilityware.com/about"
    },
    {
        text: "Careers",
        path: "http://mobilityware.com/careers.php"
    },
    {
        text: "|"
    },
    {
        text: "Contact Us",
        path: "https://www.mobilityware.com/contact"
    },
    {
        text: "Terms of Service",
        path: "http://mobilityware.com/eula.php"
    },
    {
        text: "Privacy",
        path: "http://mobilityware.com/privacy-policy.php"
    },
    {
        text: "Copyright",
        path: "http://mobilityware.com/copyright.php"
    },
    {
        text: "Sweepstakes",
        path: "https://www.mobilityware.com/sweepstakes"
    },
    {
        text: "Unsolicited Idea Submission Policy",
        path: "https://www.mobilityware.com/unsolicited-idea-submission-policy"
    }
]