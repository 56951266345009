import React from 'react'
import "./input.css"

interface InputProps {
  type: string
  className?: string
  placeholder: string
  value: any
  error?: string
  onChange?: (e: any) => void;
}

const Input = ({ type, className, value, placeholder, error, onChange, ...rest }: InputProps) => {
  return (
    <>
      <input
        type={type}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {error ? <span className='errorMessage'>{error}</span> : null}
    </>
  )
}

export default Input