import "./socialMedia.css"

export interface SocialMediaProps {
    icon: JSX.Element;
    text: string;
    path: string
}

const SocialMedia = ({ icon, text, path }: SocialMediaProps) => {
    return (
        <div className='socialMediaInner'>
            <a href={path} target="_blank">{icon}</a>
            <a href={path} target="_blank">{text}</a>
        </div>
    )
}

export default SocialMedia 