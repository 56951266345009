export const ENDPOINTS = {
    FILES: 'gdpr/files/',
    STATUS: 'gdpr/'
};

export const SERVER_MESSAGE_ENTITIES = {
    ACTIVITIES: 'activities',
    FILES: 'files'
};

export const ACTIVITY_TYPES = {
    PORTABILITY: 'Portability',
    RTBF: 'RTBF'
};

export const SERVER_ERRORS = {
    WRONG_CLAIM_CODE: 'Wrong Claim Code',
    SOMETHING_WENT_WRONG_TRY_AGAIN_IN_A_LITTLE_BIT: 'Something went wrong. Please try again later.',
    INVALID_CLAIM_CODE_PLEASE_TRY_AGAIN: 'Invalid claim code. Please try again.'
};

export const SERVER_STATUSES = {
    NOT_FOUND: 404,
    UNAVAILABLE: 0
};

export interface UserRequestState {
    claimCode: string;
    type?: string;
    statusCode: number;
    requestStatus?: string;    
    fileCount?: number;
    fileMaster?: string;
}