import { useEffect, useState } from "react";
import "./claimCode.css";
import Input from "../Input";
import Button from "../Button";
import { ImFolderDownload, ImArrowDown } from "react-icons/im";
import axios from "axios";
import baseURL from "../../config";
import { ACTIVITY_TYPES, ENDPOINTS, SERVER_ERRORS } from "../../services/service-constants";


const ClaimCode = () => {
  const [claimCode, setClaimCode] = useState<string>("");
  const [initial, setInitial] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isCompleted, SetIsCompleted] = useState(false);
  const [responseData, setResponseData] = useState<any>();

  const handleOnChange = (e: any) => {
    setClaimCode(e.target.value);
  };

  useEffect(()=>{
    let path = window.location.pathname;
    if(path.startsWith("/check/")){
      const cc = path.replace("/check/","");
      setClaimCode(cc);
      setInitial(true)
    }
  },[])

  useEffect(()=>{
    if(initial){
      submitClaimCode();
    }
  },[claimCode])

  const submitClaimCode = () => {
    const url = baseURL + ENDPOINTS.STATUS + claimCode?.trim();
    if (!claimCode?.length) {
      setErrorMessage("Please enter the Claim code.");
    } else {
      setErrorMessage("");
      axios
        .get(url)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.type === ACTIVITY_TYPES.RTBF) {
              setErrorMessage(SERVER_ERRORS.INVALID_CLAIM_CODE_PLEASE_TRY_AGAIN);
            } else {
              SetIsCompleted(true);
              if (res?.data?.requestStatus === "Completed") {
                if (res?.data?.fileList?.length) {
                  //Set Response Fields
                  setResponseData({
                    statusCode: res?.status,
                    requestStatus: res?.data?.requestStatus,
                    fileMaster: res?.data?.fileMaster,
                    files: res?.data?.fileList,
                  });
                } else {
                  setResponseData({
                    requestStatus: res?.data?.requestStatus,
                    message: "No personal data was found for this request. Thank you.",
                  });
                }
              } else if (res?.data?.requestStatus === "In Progress" || res?.data?.requestStatus === "Opened" || res?.data?.requestStatus === "Pending") {
                setResponseData({
                  requestStatus: res?.data?.requestStatus,
                  message: "We are working on your request. Please check back with us at a later time.",
                });
              } else {
                setResponseData({
                  requestStatus: res?.data?.requestStatus,
                  message: "This claim code has expired. Please resubmit your request through the game.",
                });
              }
            }
          }
        })
        .catch((err) => {
          const statusCode = err?.response?.status;
          setErrorMessage(statusCode === 400 ? SERVER_ERRORS.INVALID_CLAIM_CODE_PLEASE_TRY_AGAIN : statusCode === 500 ? SERVER_ERRORS.SOMETHING_WENT_WRONG_TRY_AGAIN_IN_A_LITTLE_BIT : "");
        });
    }
  };

  const goBackHandler = () => {
    SetIsCompleted(false);
    setClaimCode("");
  };

  const renderFiles = () => {
    if (responseData?.requestStatus === "Completed" && responseData?.fileMaster) {
      return (
        <>
          <p>Please click to download your data.</p>
          <div className="downloadBtn">
            <a
              href={`${baseURL}${ENDPOINTS.FILES}${claimCode.trim()}`}
              target="_blank"
              rel="noreferrer"
            >
              <ImArrowDown />
              <span>Download Now</span>
            </a>
          </div>
        </>
      );
    } else if (responseData?.requestStatus === "Completed" && responseData?.fileMaster === null && responseData?.files?.length) {
      return (
        <>
          <p>Please click on the links to download your data.</p>
          <div className="filesWrapper">
            {responseData?.files?.map((file: any) => {
              return (
                <div className="image-download">
                  <a
                    href={`${baseURL}${ENDPOINTS.FILES}${claimCode.trim()}/${file?.fileName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ImFolderDownload />
                    <span>{file?.fileName}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return <p className="responseMessage">{responseData?.message}</p>;
    }
  };

  return (
    <div className="claimCode">
      {!isCompleted ? (
        <div className="container">
          <h2>Please check your data request status below</h2>
          <div className="claimCodeInput">
            <Input
              type="text"
              placeholder="Enter your claim code"
              value={claimCode}
              onChange={(e) => handleOnChange(e)}
              error={errorMessage}
            />
          </div>
          <Button onClick={submitClaimCode}>Send</Button>
          <p>Your claim code is your key to access your personal data. Please keep it private.</p>
        </div>
      ) : (
        <div className="container rqst-compt">
          {responseData?.requestStatus === "Completed" ? (
            <h2>
              Your request for data is <span>{responseData?.requestStatus}</span>
            </h2>
          ) : responseData?.requestStatus === "Closed" ? (
            <h2>Your data is no longer available.</h2>
          ) : responseData?.requestStatus === "Opened" || responseData?.requestStatus === "In Progress" ? (
            <h2>
              Your request is <span>{"In Progress"}</span>
            </h2>
          ) : (
            <h2>
              Your request is <span>{responseData?.requestStatus}</span>
            </h2>
          )}

          {/* {responseData?.requestStatus === "Completed" && !responseData.message && <p>Please click on the links to download your data.</p>} */}
          {renderFiles()}
          <p className="private-msg">Your claim code is your key to access your personal data. Please keep it private.</p>
          <p
            className="claimCodeLink"
            onClick={goBackHandler}
          >
            Check another claim code
          </p>
        </div>
      )}
    </div>
  );
};

export default ClaimCode;
