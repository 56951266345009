import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ClaimCode from '../../components/claimCode'
import "./home.css"

const Home = () => {
    return (
        <div className='homePageWrapper'>
            <Header />
            <ClaimCode />
            <Footer />
        </div>
    )
}

export default Home