import "./footerMenu.css"
import { footerMenuOptions } from '../../../data'

export interface FooterMenuProps {
  text: string
  path?: string
}

const FooterMenu = () => {
  return (
    <ul className='footerMenu'>
      {
        footerMenuOptions?.map((option: FooterMenuProps, index: number) => {
          return (
            option?.path
              ?
              <li key={index}>
                <a href={option?.path}>{option?.text}</a>
              </li>
              :
              <li key={index}>{option?.text}</li>
          )
        })
      }
    </ul>
  )
}

export default FooterMenu