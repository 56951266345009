import "./footer.css"
import FooterDescription from './FooterDescription'
import SocialMedia from '../SocialMedia'
import { SocialMediaProps } from '../SocialMedia/SocialMedia'
import { TfiFacebook, TfiTwitterAlt } from "react-icons/tfi"
import { SiLinkedin, SiGlassdoor } from "react-icons/si"
import FooterMenu from './FooterMenu'

export interface SocialMediaData {
  icon: JSX.Element
  text: string
  path: string
}

const socialMediaData: SocialMediaData[] = [
  {
    icon: <TfiFacebook />,
    text: "Like us on Facebook",
    path: "https://www.facebook.com/mobilitywaregames/"
  },
  {
    icon: <TfiTwitterAlt />,
    text: "Follow us on Twitter",
    path: "https://twitter.com/MobilityWare"
  },
  {
    icon: <SiLinkedin />,
    text: "Connect on LinkedIn",
    path: "https://www.linkedin.com/company/mobilityware"
  },
  {
    icon: <SiGlassdoor />,
    text: "View us on Glassdoor",
    path: "http://www.glassdoor.com/Overview/Working-at-MobilityWare-EI_IE872824.11,23.htm"
  }
]


const Footer = () => {
  return (
    <div className='footer'>
      <div className='footerInner'>
        <FooterMenu />
        <div className='socialMedia'>
          {
            socialMediaData?.map((item: SocialMediaProps, index: number) => {
              return <SocialMedia key={item?.text + index} icon={item.icon} text={item?.text} path={item?.path} />
            })
          }
        </div>
        <FooterDescription />
      </div>
    </div>
  )
}

export default Footer