import React from 'react'
import "./button.css"

interface ButtonProps {
  children: string
  className?: string
  onClick?: () => void
}

const Button = ({ children, className, onClick, ...rest }: ButtonProps) => {
  return (
    <button className={`${className} button`} onClick={onClick} {...rest}>
      {children}
    </button>
  )
}

export default Button