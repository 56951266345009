import "./footerDescription.css"

const FooterDescription = () => {
    return (
        <div className='footerDescription'>
            <div className='footerDescriptionSlide'>
                <a href='https://www.mobilityware.com/'><img src='Images/logo-footer.png' alt='Mobilityware logo' /></a>
            </div>
            <p>
                © 2003-2023 MobilityWare. All rights reserved. iPhone™, iPad™, and iPod touch® are trademarks of Apple Inc.
                Android™ is a trademark of Google Inc. Amazon™ is a trademark of Amazon Inc. All other trademarks referenced herein are registered
                trademarks of their respective companies.
            </p>
        </div>
    )
}

export default FooterDescription