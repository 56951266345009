import React from 'react'
import "./header.css"

const Header = () => {
  return (
    <div className='header'>
      <div className='container'>
        <a href='https://www.mobilityware.com/'><img src='/Images/logo-header.png' alt='Mobilityware logo' /></a>
      </div>
    </div>
  )
}

export default Header